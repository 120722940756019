// React imports
import React, { Fragment } from "react";

// Material imports
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ButtonMenu from "../components/ButtonMenu";

// Other Imports
import PropTypes from "prop-types";
import CompetitionFavouriteButton from "./CompetitionFavouriteButton";
import { EVENT_IMAGE } from "../utils/imageUtils";
import CheckIcon from "@material-ui/icons/Check";
import withWidth from "@material-ui/core/withWidth";

// Icon imports

const styles = {
  cCard: {
    display: "flex",
    justifyContent: "space-between",
    margin: 5
  },
  cDetails: {
    display: "flex",
    flexDirection: "column"
  },
  cContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  cCover: {
    width: 151,
    height: 151
  },
  innerContent: {
    display: "flex",
    alignItems: "center"
  },
  margin: {
    marginBottom: "10px"
  },
  cButton: {
    color: "#7e886e"
  },
  media: {
    // ⚠️ object-fit is not supported by IE11.
    objectFit: "cover"
  },
  eventTeaserCard: {
    display: "flex",
    marginBottom: "20px"
  },
  eventTeaserDetails: {
    display: "flex",
    flexDirection: "column"
  },
  eventTeaserContent: {
    flex: "1 0 auto"
  },
  eventTeaserCover: {
    minWidth: "151px",
    objectFit: "cover"
  },
  eventTeaserAdCover: {
    minWidth: "151px",
    objectFit: "cover",
    backgroundSize: "contain"
  },
  eventTeaserControls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "14px",
    paddingBottom: "8px"
  },
  eventTeaserDate: {
    marginBottom: "8px"
  },
  eventTeaserButton: {
    color: "#7e886e"
  },
  simpleCard: {
    marginBottom: "20px"
  },
  simpleCardWOHeader: {
    marginBottom: "20px",
    height: "100%"
  },
  simpleButton: {
    color: "#7e886e",
    padding: "7px 12px",
    margin: 0
  },
  mediaCard: {
    marginBottom: "20px",
    height: "100%"
  },
  mediaMedia: {
    height: "140px"
  },
  mediaButton: {
    color: "#7e886e",
    padding: "7px 8px",
    margin: "0 4px"
  },
  eventListCard: {
    display: "flex",
    marginBottom: "20px"
  },
  eventListCardXs: {
    display: "flex",
    marginBottom: "20px",
    width: "95vw"
  },
  eventAdListCard: {
    display: "flex"
  },
  eventListDetails: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  eventListContent: {
    flex: "1 0 auto"
  },
  eventListCover: {
    minWidth: "151px",
    objectFit: "cover"
  },
  eventListCoverXs: {
    minWidth: "96px",
    objectFit: "cover",
    marginRight: "8px"
  },
  eventListControls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "14px",
    paddingBottom: "8px"
  },
  eventListControlsXs: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "8px",
    justifyContent: "flex-end"
  },
  eventListDate: {
    marginBottom: "8px"
  },
  eventListInfo: {
    marginBottom: "8px"
  },
  eventListInfoXs: {
    marginBottom: "8px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  eventListButton: {
    color: "#7e886e"
  },
  eventListFavButton: {
    marginTop: "20px",
    alignItems: "flex-end"
  },
  clubListCard: {
    display: "flex",
    marginBottom: "20px",
    height: "100%"
  },
  clubListDetails: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  clubListContent: {
    flex: "1 0 auto"
  },
  clubListCover: {
    minWidth: "151px",
    backgroundSize: "contain",
    marginLeft: "14px"
  },
  clubListControls: {
    display: "flex",
    justifyContent: "flex-end",
    paddingLeft: "14px",
    paddingRight: "14px",
    paddingBottom: "8px"
  },
  clubListDate: {
    marginBottom: "8px"
  },
  clubListInfo: {
    marginBottom: "8px"
  },
  clubListButton: {
    color: "#7e886e"
  }
};

export const EventTeaserCard = props => {
  return (
    <Card style={styles.eventTeaserCard}>
      <CardMedia
        style={styles.eventTeaserCover}
        image={props.isEvent ? EVENT_IMAGE : props.imageUrl}
        title={props.imageTitle}
        onClick={props.btnClickHandler}
      />

      <div style={styles.eventTeaserDetails}>
        <CardContent style={styles.eventTeaserContent}>
          <Typography variant="subheading">{props.header}</Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={styles.eventTeaserDate}
          >
            {props.infoIcons}
          </Typography>

          <Typography variant="body1">
            {props.translate("eventCard.registrationStart") +
              ": " +
              props.registrationStart}
          </Typography>
          <Typography variant="body1">{props.infoText}</Typography>
        </CardContent>
        <div style={styles.eventTeaserControls}>
          <Button
            size="small"
            style={styles.eventTeaserButton}
            href={props.href}
            onClick={props.btnClickHandler}
          >
            {props.buttonLabel}
          </Button>
        </div>
      </div>
    </Card>
  );
};

EventTeaserCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageTitle: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  infoText: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  btnClickHandler: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  infoIcons: PropTypes.object.isRequired
};

export const EventAdTeaserCard = props => {
  return (
    <Card style={styles.eventTeaserCard}>
      <CardMedia
        style={styles.eventTeaserAdCover}
        image={props.image}
        title={props.imageTitle}
        onClick={props.btnClickHandler}
      />

      <div style={styles.eventTeaserDetails}>
        <CardContent style={styles.eventTeaserContent}>
          <Typography variant="subheading">{props.header}</Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={styles.eventTeaserDate}
          >
            Anzeige
          </Typography>
          <Typography variant="body1">{props.desc}</Typography>
        </CardContent>
        <div style={styles.eventTeaserControls}>
          <Button
            size="small"
            style={styles.eventTeaserButton}
            onClick={() =>
              props.handleAdClick(props.detailId, props.detailView, props.href)
            }
          >
            {props.buttonLabel}
          </Button>
        </div>
      </div>
    </Card>
  );
};

EventAdTeaserCard.propTypes = {
  imageTitle: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  btnClickHandler: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  detailView: PropTypes.string.isRequired,
  detailId: PropTypes.string.isRequired
};

export const SimpleCard = props => {
  return (
    <Card style={styles.simpleCard}>
      <CardHeader
        action={
          <IconButton onClick={props.actionClickHandler}>
            {props.actionIcon}
          </IconButton>
        }
        title={<Typography variant="headline">{props.header}</Typography>}
      />
      <CardContent>
        <Typography variant="body1">{props.content}</Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          style={styles.simpleButton}
          href={props.href}
          onClick={props.btnClickHandler}
        >
          {props.buttonLabel}
        </Button>
      </CardActions>
    </Card>
  );
};

SimpleCard.propTypes = {
  header: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  btnClickHandler: PropTypes.func.isRequired,
  actionIcon: PropTypes.string.isRequired,
  actionClickHandler: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired
};

export const SimpleCardWithoutHeader = props => {
  return (
    <Card style={styles.simpleCard}>
      <CardContent>
        <Fragment>{props.content}</Fragment>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          style={styles.simpleButton}
          href={props.href}
          onClick={props.btnClickHandler}
        >
          {props.buttonLabel}
        </Button>
      </CardActions>
    </Card>
  );
};

SimpleCard.propTypes = {
  header: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  btnClickHandler: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired
};
export const MediaCard = props => {
  return (
    <Card style={styles.mediaCard}>
      <CardMedia
        style={styles.mediaMedia}
        image={props.imageUrl}
        title={props.imageTitle}
      />
      <CardContent>
        <Typography variant="headline">{props.header}</Typography>
        <Typography variant="body1">{props.content}</Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          style={styles.mediaButton}
          href={props.href}
          onClick={props.btnClickHandler}
        >
          {props.buttonLabel}
        </Button>
      </CardActions>
    </Card>
  );
};

MediaCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageTitle: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  btnClickHandler: PropTypes.func.isRequired,
  href: PropTypes.string.isRequired
};

export const EventListCard = withWidth()(props => {
  const { width } = props;
  return width === "xs" ? (
    <Card style={styles.eventListCardXs}>
      <div style={styles.eventListDetails}>
        <CardHeader
          title={props.header}
          action={
            <div>
              {props.registered === true ? (
                <IconButton
                  disabled={true}
                  style={{ color: props.paid ? "green" : "red" }}
                >
                  <CheckIcon />
                </IconButton>
              ) : null}
              {props.fav === true || props.fav === false ? (
                <IconButton>
                  <CompetitionFavouriteButton
                    key={props.id}
                    fav={props.fav}
                    competitionId={props.id}
                    style={styles.eventListFavButton}
                  />
                </IconButton>
              ) : null}
              <ButtonMenu
                buttonIcon={<MoreVertIcon />}
                options={props.actions}
              />
            </div>
          }
        />
        <CardContent style={styles.eventListContent}>
          <div style={{ display: "flex" }}>
            <CardMedia
              style={styles.eventListCoverXs}
              image={props.isEvent ? EVENT_IMAGE : props.imageUrl}
              title={props.imageTitle}
              onClick={props.btnClickHandler}
            />
            <div>
              <Typography
                variant="body1"
                color="textSecondary"
                style={styles.eventListInfoXs}
              >
                <span style={styles.eventListInfoXs}>{props.infoIcons}</span>
              </Typography>
              <Typography variant="body1" style={styles.eventListInfoXs}>
                <span>
                  {props.translate("eventCard.registrationStart") + ": "}
                </span>
                <span>{props.registrationStart}</span>
              </Typography>

              <Typography variant="body1" style={styles.eventListInfo}>
                {props.translate(
                  "resources.competitionshow.detailsTable.clubName"
                ) +
                  " " +
                  props.clubName}
              </Typography>
              <Typography variant="body1" style={styles.eventListInfo}>
                {props.translate(
                  "resources.competitionshow.detailsTable.location"
                ) +
                  " " +
                  props.location}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={props.googleMapsLink}
                  style={styles.simpleBtn}
                >
                  {props.translate(
                    "resources.competitionshow.detailsTable.googleMapsLink"
                  )}
                </a>
              </Typography>
              <Typography variant="body1" style={styles.eventListInfoXs}>
                <span>
                  {props.translate("eventCard.freePlaces") +
                    ": " +
                    props.freeSpots}
                </span>
              </Typography>
            </div>
          </div>
        </CardContent>
        <div style={styles.eventListControlsXs}>
          <Button
            size="small"
            style={styles.eventListButton}
            onClick={props.btnClickHandler}
          >
            {props.buttonLabel}
          </Button>
        </div>
      </div>
    </Card>
  ) : (
    <Card style={styles.eventListCard}>
      <CardMedia
        style={styles.eventListCover}
        image={props.isEvent ? EVENT_IMAGE : props.imageUrl}
        title={props.imageTitle}
        onClick={props.btnClickHandler}
      />

      <div style={styles.eventListDetails}>
        <CardContent style={styles.eventListContent}>
          <Typography variant="subheading" onClick={props.btnClickHandler}>
            {props.header}
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={styles.eventListDate}
          >
            {props.infoIcons}
          </Typography>
          <Typography variant="body1" style={styles.eventListInfo}>
            {props.translate("eventCard.registrationStart") +
              ": " +
              props.registrationStart}
          </Typography>
          <Typography variant="body1" style={styles.eventListInfo}>
            {props.translate(
              "resources.competitionshow.detailsTable.clubName"
            ) +
              " " +
              props.clubName}
          </Typography>
          <Typography variant="body1" style={styles.eventListInfo}>
            {props.translate(
              "resources.competitionshow.detailsTable.location"
            ) +
              " " +
              props.location}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={props.googleMapsLink}
              style={styles.simpleBtn}
            >
              {props.translate(
                "resources.competitionshow.detailsTable.googleMapsLink"
              )}
            </a>
          </Typography>
          <Typography variant="body1" style={styles.eventListInfo}>
            {props.translate("eventCard.freePlaces") + ": " + props.freeSpots}
          </Typography>
        </CardContent>
        <div style={styles.eventListControls}>
          <Button
            size="small"
            style={styles.eventListButton}
            onClick={props.btnClickHandler}
          >
            {props.buttonLabel}
          </Button>
        </div>
      </div>
      {props.registered === true ? (
        <CardHeader
          action={
            <IconButton
              disabled={true}
              style={{ color: props.paid ? "green" : "red" }}
            >
              <CheckIcon />
            </IconButton>
          }
        />
      ) : null}
      {props.fav === true || props.fav === false ? (
        <CardHeader
          action={
            <IconButton>
              <CompetitionFavouriteButton
                key={props.id}
                fav={props.fav}
                competitionId={props.id}
                style={styles.eventListFavButton}
              />
            </IconButton>
          }
        />
      ) : null}
      <CardHeader
        action={
          <ButtonMenu buttonIcon={<MoreVertIcon />} options={props.actions} />
        }
      />
    </Card>
  );
});

EventListCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageTitle: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  infoText: PropTypes.string.isRequired,
  btnClickHandler: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  fav: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired
};

export const EventAdListCard = props => {
  return (
    <Card style={styles.eventAdListCard}>
      <CardMedia
        style={styles.eventListCover}
        image={props.imageUrl}
        title={props.imageTitle}
        onClick={props.btnClickHandler}
      />
      <div style={styles.eventListDetails}>
        <CardContent style={styles.eventListContent}>
          <Typography variant="subheading" onClick={props.btnClickHandler}>
            {props.header}
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={styles.eventListDate}
          >
            Anzeige
          </Typography>
          <Typography variant="body1" style={styles.eventListInfo}>
            {props.desc}
          </Typography>
        </CardContent>
        <div style={styles.eventListControls}>
          <Button
            size="small"
            style={styles.eventTeaserButton}
            onClick={() => {
              props.handleAdClick(props.detailId, props.detailView, props.href);
            }}
          >
            {props.buttonLabel}
          </Button>
        </div>
      </div>
      {props.fav == true || props.fav == false ? (
        <CardHeader
          action={
            <IconButton>
              <CompetitionFavouriteButton
                fav={props.fav}
                competitionId={props.id}
                style={styles.eventListFavButton}
              />
            </IconButton>
          }
        />
      ) : null}
      <CardHeader
        action={
          <ButtonMenu buttonIcon={<MoreVertIcon />} options={props.actions} />
        }
      />
    </Card>
  );
};

EventAdListCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageTitle: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  btnClickHandler: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  fav: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired
};

export const ClubListCard = props => {
  return (
    <Card style={styles.clubListCard}>
      <CardMedia
        style={styles.clubListCover}
        image={props.imageUrl}
        title={props.imageTitle}
      />
      <div style={styles.clubListDetails}>
        <CardContent style={styles.clubListContent}>
          <Typography variant="headline">{props.name}</Typography>
          <Typography variant="body1" style={styles.clubListAddress}>
            {props.address}, {props.zip} {props.place}
          </Typography>
          <Typography variant="body1">
            Kontaktperson: {props.contactPerson}
          </Typography>
        </CardContent>
        <div style={styles.clubListControls}>
          <Button
            size="small"
            style={styles.clubListButton}
            onClick={props.btnClickHandler}
          >
            {props.buttonLabel}
          </Button>
        </div>
      </div>
    </Card>
  );
};

ClubListCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageTitle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
  contactPerson: PropTypes.string.isRequired,
  btnClickHandler: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired
};
