// React imports
import React, { Fragment } from "react";

// React Admin imports
import { translate } from "react-admin";

// Material imports
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ErrorMessage from "../ErrorMessage";
import PropTypes from "prop-types";

// Other imports
import { EventAdListCard, EventListCard } from "../../components/Cards";
import moment from "moment";
import CompetitionInformationIcons from "../CompetitionInformationIcons";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import { setPopup } from "../../reducer/flagActions";
import {
  getAdsImageSrc,
  getCompetitionListImageSrc
} from "../../utils/imageUtils";
import { rowsPerPageOptions } from "../../utils/paging";
import { FILE_API_PATH } from "../../utils/config";
import CheckIcon from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import CompetitionFavouriteButton from "../CompetitionFavouriteButton";

const styles = {
  simpleTitle: {
    marginTop: "20px",
    marginBottom: "20px",
    whiteSpace: "nowrap"
  },
  listViewHeader: {
    fontWeight: "bold",
    fontSize: 15
  }
};

const fetchFileFromBucket = filePath => {
  window.open(FILE_API_PATH + "/" + filePath);
};

function getFreeSpots(competition) {
  let totalSlots = 0;
  let bookedSlots = 0;
  for (let i = 0; i < competition.squads.length; i++) {
    if (!competition.squads[i].multipleWaitingList) {
      totalSlots += competition.squads[i].squadSize;
      bookedSlots +=
        competition.squads[i].squadSize >=
        competition.squads[i].subscriptionSize
          ? competition.squads[i].subscriptionSize
          : competition.squads[i].squadSize;
    }
  }
  return totalSlots - bookedSlots + " / " + totalSlots;
}

function getDownloadsActions(competition, translate) {
  let actions = [];
  if (
    (competition.competitionUploads &&
      competition.competitionUploads.length > 0) ||
    (competition.resultListLink !== null &&
      competition.resultListLink !== undefined)
  ) {
    if (
      competition.competitionUploads &&
      competition.competitionUploads.length > 0
    ) {
      competition.competitionUploads.map(competitionUpload =>
        actions.push({
          text: competitionUpload.type.showName
            ? competitionUpload.document.fileName
            : competitionUpload.type.name,
          icon: <DownloadIcon />,
          callBack: () => fetchFileFromBucket(competitionUpload.document.path)
        })
      );
    }
    if (
      competition.resultListLink !== null &&
      competition.resultListLink !== undefined
    ) {
      actions.push({
        text: translate("resources.competitionshow.resultListLink"),
        icon: <DownloadIcon />,
        callBack: () => (document.location.href = competition.resultListLink)
      });
    }
  } else
    actions = [
      {
        text: translate("NO_DOWNLOADS_AVAILABLE"),
        icon: <DownloadIcon />
      }
    ];

  return actions;
}

function getFreeSpotsIncludingWaitingList(competition) {
  let totalSlots = 0;
  let bookedSlots = 0;
  for (let i = 0; i < competition.squads.length; i++) {
    totalSlots +=
      competition.squads[i].squadSize + competition.squads[i].subscriptionSize;
    bookedSlots += competition.squads[i].members
      ? competition.squads[i].members.length
      : 0;
  }
  return totalSlots - bookedSlots;
}

const CompetitionList = props => {
  const { translate, listType, isResults } = props;
  moment.locale(props.locale);
  return (
    <Fragment>
      {listType ? (
        <div
          style={{
            width: "100%",
            overflowX: "scroll",
            margin: "8px",
            overflowY: "hidden"
          }}
        >
          <div
            style={{
              display: "table",
              tableLayout: "fixed",
              width: "100%"
            }}
          >
            <Table className={"competitions"}>
              <TableHead component={Paper}>
                <TableRow>
                  <TableCell style={styles.listViewHeader} padding="dense">
                    {translate("resources.competitions.listPage.matchDate")}
                  </TableCell>
                  <TableCell style={styles.listViewHeader} padding="none">
                    {translate("resources.competitions.listPage.region")}
                  </TableCell>
                  <TableCell style={styles.listViewHeader} padding="dense">
                    {translate("resources.competitions.listPage.metchPlace")}
                  </TableCell>
                  <TableCell style={styles.listViewHeader} padding="none">
                    {translate("resources.competitions.listPage.club")}
                  </TableCell>
                  <TableCell style={styles.listViewHeader} padding="dense">
                    {translate("resources.competitions.listPage.matchName")}
                  </TableCell>
                  <TableCell style={styles.listViewHeader} padding="none">
                    {translate("resources.competitions.listPage.level")}
                  </TableCell>
                  <TableCell style={styles.listViewHeader} padding="dense">
                    {translate("resources.competitions.listPage.matchType")}
                  </TableCell>
                  <TableCell style={styles.listViewHeader} padding="none">
                    {translate("resources.competitions.listPage.stages")}
                  </TableCell>
                  <TableCell style={styles.listViewHeader} padding="dense">
                    {translate(
                      "resources.competitions.listPage.registrationStartDate"
                    )}
                  </TableCell>
                  <TableCell style={styles.listViewHeader} padding="dense">
                    {translate("resources.competitions.listPage.freeSlots")}
                  </TableCell>
                  <TableCell style={styles.listViewHeader} padding="none">
                    {""}
                  </TableCell>
                  <TableCell
                    style={styles.listViewHeader}
                    colspan="2"
                    padding="dense"
                  >
                    {translate("resources.competitions.listPage.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              {Object.keys(props.competitionGroups).map((key, index) => [
                <>
                  <Typography variant="headline" style={styles.simpleTitle}>
                    {moment(key + "-01").format("MMMM YYYY")}
                  </Typography>
                  <TableBody component={Paper}>
                    {props.competitionGroups[key].map((record, oKey) => (
                      <>
                        <TableRow key={record.id}>
                          <TableCell padding="dense">
                            <Typography noWrap>
                              {moment(record.competitionDate)
                                .format(
                                  record.competitionEndDate
                                    ? "ddd DD.MM"
                                    : props.momentFormat
                                )
                                .toString()}

                              {record.competitionEndDate
                                ? " - " +
                                  moment(record.competitionEndDate)
                                    .format("ddd L")
                                    .toString()
                                : null}
                            </Typography>
                          </TableCell>
                          <TableCell padding="none">
                            <Typography noWrap>
                              {record.country
                                ? record.country.toUpperCase()
                                : null}
                            </Typography>
                          </TableCell>
                          <TableCell padding="dense">
                            <Typography noWrap>{record.city}</Typography>
                          </TableCell>
                          <TableCell
                            padding="none"
                            style={{ maxWidth: "10rem" }}
                          >
                            <Typography noWrap>
                              {record.clubShortName}{" "}
                            </Typography>
                          </TableCell>
                          <TableCell
                            padding="dense"
                            style={{ maxWidth: "15rem" }}
                          >
                            <Typography noWrap>{record.name}</Typography>
                          </TableCell>
                          <TableCell padding="none">
                            <Typography noWrap>{record.levelName} </Typography>
                          </TableCell>
                          <TableCell
                            padding="dense"
                            style={{ maxWidth: "12rem" }}
                          >
                            <Typography noWrap>
                              {record.event
                                ? record.eventType
                                : record.competitionTypeName}
                            </Typography>
                          </TableCell>
                          <TableCell padding="none">
                            <Typography noWrap>
                              {record.event ? null : record.stages}
                            </Typography>
                          </TableCell>
                          <TableCell padding="dense">
                            <Typography noWrap>
                              {moment(record.registrationStart)
                                .format("ddd DD.MM.YYYY HH:mm")
                                .toString()}
                            </Typography>
                          </TableCell>
                          <TableCell padding="dense">
                            <Typography noWrap>
                              {getFreeSpots(record)}
                            </Typography>
                          </TableCell>

                          <TableCell padding="none">
                            <Typography noWrap>
                              {record.favourite === true ||
                              record.favourite === false ? (
                                <IconButton>
                                  <CompetitionFavouriteButton
                                    key={record.id}
                                    fav={record.favourite}
                                    competitionId={record.id}
                                    style={styles.eventListFavButton}
                                  />
                                </IconButton>
                              ) : null}
                              {record.registered === true ? (
                                <IconButton
                                  disabled={true}
                                  style={{
                                    color: record.paid ? "green" : "red"
                                  }}
                                >
                                  <CheckIcon />
                                </IconButton>
                              ) : null}
                            </Typography>
                          </TableCell>

                          <TableCell padding="none">
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              style={{ margin: "5px" }}
                              onClick={record.publicLinkAction}
                            >
                              {translate(
                                "resources.competitions.listPage.details"
                              )}
                            </Button>
                          </TableCell>

                          <TableCell padding="none">
                            {isResults ? null : (
                              <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                style={{ margin: "5px", float: "left" }}
                                onClick={() => {
                                  record.publicLinkAction();
                                  props.dispatch(
                                    setPopup(
                                      "popupCompetitionRegistrationForm",
                                      true
                                    )
                                  );
                                }}
                                disabled={
                                  getFreeSpotsIncludingWaitingList(record) <= 0
                                }
                              >
                                {translate(
                                  "resources.competitions.listPage.register"
                                )}
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                        {record.ads === true &&
                          record.ad_index > -1 &&
                          props.ads[record.ad_index] && (
                            <TableRow>
                              <TableCell colspan="13" style={{ padding: "0" }}>
                                <EventAdListCard
                                  handleAdClick={props.handleAdClick}
                                  actions={""}
                                  freeSpots={""}
                                  key={"ads-" + oKey}
                                  imageUrl={getAdsImageSrc(
                                    props.ads && props.ads[record.ad_index]
                                      ? props.ads[record.ad_index]
                                      : null
                                  )}
                                  imageTitle={props.ads[record.ad_index].n}
                                  header={props.ads[record.ad_index].name}
                                  desc={props.ads[record.ad_index].shortName}
                                  id={record.id}
                                  buttonLabel={
                                    props.ads[record.ad_index].buttonText
                                  }
                                  href={props.ads[record.ad_index].buttonLink}
                                  detailView={
                                    props.ads[record.ad_index].detailView
                                  }
                                  detailId={props.ads[record.ad_index].id}
                                  infoIcons={
                                    <CompetitionInformationIcons
                                      record={record}
                                    />
                                  }
                                  translate={props.translate}
                                  registrationStart={moment(
                                    record.registrationStart
                                  )
                                    .format(props.momentFormatWithTime)
                                    .toString()}
                                />
                              </TableCell>
                            </TableRow>
                          )}
                      </>
                    ))}
                  </TableBody>
                </>
              ])}
            </Table>
          </div>
        </div>
      ) : (
        <>
          {Object.keys(props.competitionGroups).map((key, index) => [
            <Grid container spacing={16}>
              <Grid item xs={12} sm={12} md={8}>
                <Typography variant="headline" style={styles.simpleTitle}>
                  {moment(key + "-01").format("MMMM YYYY")}
                </Typography>
                {props.competitionGroups[key].map((record, oKey) => (
                  <div>
                    <EventListCard
                      actions={getDownloadsActions(record, translate)}
                      freeSpots={getFreeSpots(record)}
                      clubName={record.clubName}
                      location={
                        record.address +
                        ", " +
                        record.zip +
                        " " +
                        record.city +
                        " "
                      }
                      googleMapsLink={record.googleMapsLink}
                      key={"competition-" + oKey}
                      imageUrl={getCompetitionListImageSrc(
                        record.competitionTypeImage
                      )}
                      imageTitle={record.imageTitle}
                      header={record.name}
                      fav={record.favourite}
                      registered={record.registered}
                      paid={record.paid}
                      date={moment(record.competitionDate)
                        .format(props.momentFormat)
                        .toString()}
                      club={record.clubName}
                      place={record.city}
                      infoText={record.information}
                      stages={record.stages}
                      type={record.competitionTypeName}
                      level={record.levelName}
                      id={record.id}
                      isEvent={record.event}
                      eventType={record.eventType}
                      buttonLabel={
                        record.event
                          ? translate(
                              "resources.competitions.listPage.eventButtonDetails"
                            )
                          : translate(
                              "resources.competitions.listPage.buttonDetails"
                            )
                      }
                      infoIcons={
                        <CompetitionInformationIcons record={record} />
                      }
                      btnClickHandler={record.publicLinkAction}
                      translate={props.translate}
                      registrationStart={moment(record.registrationStart)
                        .format(props.momentFormatWithTime)
                        .toString()}
                    />
                    {record.ads === true &&
                    record.ad_index > -1 &&
                    props.ads[record.ad_index] ? (
                      <div style={{ marginBottom: "20px" }}>
                        <EventAdListCard
                          handleAdClick={props.handleAdClick}
                          actions={""}
                          freeSpots={""}
                          key={"ads-" + oKey}
                          imageUrl={getAdsImageSrc(
                            props.ads && props.ads[record.ad_index]
                              ? props.ads[record.ad_index]
                              : null
                          )}
                          imageTitle={props.ads[record.ad_index].n}
                          header={props.ads[record.ad_index].name}
                          desc={props.ads[record.ad_index].shortName}
                          id={record.id}
                          buttonLabel={props.ads[record.ad_index].buttonText}
                          href={props.ads[record.ad_index].buttonLink}
                          detailView={props.ads[record.ad_index].detailView}
                          detailId={props.ads[record.ad_index].id}
                          infoIcons={
                            <CompetitionInformationIcons record={record} />
                          }
                          translate={props.translate}
                          registrationStart={moment(record.registrationStart)
                            .format(props.momentFormatWithTime)
                            .toString()}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                ))}
              </Grid>
            </Grid>
          ])}
        </>
      )}

      <TablePagination
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} ${props.translate(
            "resources.competitions.listPage.of"
          )} ${count}`
        }
        labelRowsPerPage={props.translate(
          "resources.competitions.listPage.rowsPerPage"
        )}
        rowsPerPageOptions={rowsPerPageOptions}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        onChangePage={props.handlePageChange}
        onChangeRowsPerPage={props.handleRowsPerPageChange}
        count={props.total}
      />
      <ErrorMessage
        errorMessage={props.errorMessage}
        open={props.errorMessage ? true : false}
        onClose={props.hideError}
        translate={props.translate}
      />
    </Fragment>
  );
};
CompetitionList.propTypes = {
  competitionGroups: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  errorMessage: PropTypes.object,
  ads: PropTypes.object
};
export default translate(CompetitionList);
