import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import deLocale from "date-fns/locale/de";
import Chip from "@material-ui/core/Chip";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import moment from "moment-timezone";
import RichTextInput from "ra-input-rich-text";
import React, { Component } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Edit,
  FileInput,
  FormDataConsumer,
  FormTab,
  minLength,
  maxLength,
  minValue,
  NumberInput,
  RadioButtonGroupInput,
  REDUX_FORM_NAME,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  translate,
  WithPermissions
} from "react-admin";
import { DateInput, DateTimeInput, TimeInput } from "react-admin-date-inputs";
import { connect } from "react-redux";
import { change } from "redux-form";
import FileFieldInput from "../../components/inputs/FileFieldInput";
import S3FileUploadsPreview from "../../components/inputs/S3FileUploadsPreview";
import PageTitle from "../../components/PageTitle";
import { setError } from "../../reducer/errorActions";
import {
  getCompetitionClasses,
  getCompetitionFactors,
  getDivisionsFor,
  getNotificationTypes,
  getUploadTypes,
  upload
} from "../../utils/restUtils";
import { isAdmin, isClubAdmin, isPortalAdmin } from "../../utils/utils";
import {
  globalPaymentMethodValidator,
  link,
  required,
  waitingListRequired
} from "../../validators/validators";
import CustomToolbar from "../../components/CustomToolbar";
import { getCompetitionListImageSrc } from "../../utils/imageUtils";
import countries from "./../../components/pages/country-codes-case-lower.json";
import { togglePopup } from "../../reducer/flagActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

const RenderCountryOptions = () => {
  return countries.map(country => {
    return { id: country.countryCode, name: country.countryName };
  });
};

class CompetitionEdit extends Component {
  state = {
    divisionChoices: [],
    squadDivisions: [],
    squadFactors: [],
    squadCompetitionClasses: [],
    factors: null,
    competitionClasses: null,
    defaultNotificationBlocks: [],
    uploadTypes: null,
    oldCompetitionDate: null,
    newCompetitionDate: null,
    competition: null
  };
  copySquad = squadID => {
    try {
      let squads = this.props.squads ? this.props.squads : [];
      let data = squads[this.getSquadId(squadID)];
      let copy = { ...data };
      copy.id = null;
      squads.push(copy);
      this.props.dispatch(change(REDUX_FORM_NAME, "squads", squads));
    } catch (e) {
      /*nothing*/
    }
  };
  removeSquad = squadID => {
    try {
      let squads = this.props.squads ? this.props.squads : [];
      squads.splice(this.getSquadId(squadID), 1);
      this.props.dispatch(change(REDUX_FORM_NAME, "squads", squads));
    } catch (e) {
      /*nothing*/
    }
  };

  getSquadId = squadID => {
    return squadID.split("[")[1].split("]")[0];
  };

  getSquad = squadID => {
    try {
      let squads = this.props.squads ? this.props.squads : [];
      return squads[this.getSquadId(squadID)];
    } catch (e) {
      /*nothing*/
    }
  };
  toggleStands = squadID => {
    let squads = this.props.squads ? this.props.squads : [];
    let squad = squads[this.getSquadId(squadID)];
    squad.stands = [{ name: "" }];
    this.props.dispatch(change(REDUX_FORM_NAME, "squads", squads));
  };
  validateSquadSize = (message = "ERROR.SQUAD_STAND_SIZE_NOT_MATCH") => (
    value,
    allValues,
    props,
    field
  ) => {
    const squad = this.getSquad(field);
    return squad &&
      squad.stands &&
      squad.stands.length > 0 &&
      squad.stands.length !== value
      ? props.translate(message)
      : undefined;
  };

  initData = () => {
    moment.tz.setDefault("UTC");

    getNotificationTypes().then(
      data => {
        let defaultBlocks = [];
        for (let i = 0; i < data.length; i++) {
          defaultBlocks.push({
            text: "",
            type: data[i]
          });
        }
        this.setState({
          defaultNotificationBlocks: defaultBlocks
        });
      },
      () => {}
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.competitionUploadsExisting &&
      this.state.uploadTypes === null
    ) {
      this.getUploadTypesData();
    }

    if (
      prevProps.formExists &&
      prevState.defaultNotificationBlocks.length === 0 &&
      this.state.defaultNotificationBlocks.length > 0 &&
      this.props.notificationBlocks
    ) {
      let tmpBlocks = [];
      for (let i = 0; i < this.state.defaultNotificationBlocks.length; i++) {
        for (let j = 0; j < this.props.notificationBlocks.length; j++) {
          if (
            this.state.defaultNotificationBlocks[i].type.id ===
            this.props.notificationBlocks[j].type.id
          ) {
            tmpBlocks[i] = this.props.notificationBlocks[j];
          }
        }
        if (!tmpBlocks[i]) {
          tmpBlocks[i] = this.state.defaultNotificationBlocks[i];
        }
      }
      this.props.dispatch(
        change(REDUX_FORM_NAME, "notificationBlocks", tmpBlocks)
      );
    }

    if (prevProps.formExists === false && this.props.formExists === true) {
      this.initData();
    }

    if (
      this.state.divisionChoices.length === 0 &&
      this.props.formExists === true &&
      this.props.competitionTypeId &&
      prevProps.competitionTypeId !== this.props.competitionTypeId //&&
      //this.props.competitionTypeId //&&
      //this.state.divisionChoices &&
      //this.state.divisionChoices.length === 0
    ) {
      getDivisionsFor(this.props.competitionTypeId).then(
        data => {
          this.setState({
            divisionChoices: data.data,
            squadDivisions: data.data.filter(
              d =>
                !this.props.competition.division_ids ||
                this.props.competition.division_ids.includes(d.id)
            ),
            squadFactors: this.props.competition.factor || [],
            squadCompetitionClasses:
              this.props.competition.competitionClass || []
          });
        },
        e => {
          this.props.dispatch(setError(e));
        }
      );
    }
  }

  getDivisions = id => {
    if (id) {
      getDivisionsFor(id).then(
        data => {
          this.setState({
            divisionChoices: data.data,
            squadDivisions: []
          });
          this.props.dispatch(change(REDUX_FORM_NAME, "division_ids", null));
        },
        e => {
          this.props.dispatch(setError(e));
        }
      );
    } else {
      this.setState({ divisionChoices: [], squadDivisions: [] });
    }
  };

  checkIsAdmin = permissions => {
    return isAdmin(permissions);
  };

  checkIsClubAdmin = permissions => {
    return isClubAdmin(permissions);
  };

  getUploadTypesData = () => {
    const competitionUploadsExisting = this.props.competitionUploadsExisting;
    let defaultUploadType = [];
    this.setState({ uploadTypes: "loading" });
    getUploadTypes(0, 100, "sortingOrder", "asc", {}).then(
      data => {
        this.setState({
          uploadTypes: data.data
        });
        let currDoc = null;
        for (let i = 0; i < data.data.length; i++) {
          currDoc = null;
          if (competitionUploadsExisting !== undefined) {
            for (let j = 0; j < competitionUploadsExisting.length; j++) {
              if (data.data[i].id === competitionUploadsExisting[j].type.id) {
                currDoc = competitionUploadsExisting[j].document;
              }
            }
          }
          if (currDoc == null) {
            defaultUploadType.push({
              documentRaw: {
                rawFile: { documentId: null },
                id: null,
                fileName: null
              },
              document: {},
              type: data.data[i]
            });
          } else {
            defaultUploadType.push({
              documentRaw: {
                rawFile: { documentId: currDoc.id },
                id: currDoc.id,
                fileName: currDoc.fileName
              },
              document: currDoc,
              type: data.data[i]
            });
          }
        }
        this.props.dispatch(
          change(REDUX_FORM_NAME, "competitionUploadsOpt", defaultUploadType)
        );
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  uploadFileSuccess = (data, rawFile, index) => {
    const filename = data.key.replace(/ /g, "+");

    rawFile.documentId = data.id;
    rawFile.errorMsg = null;
    const { dispatch } = this.props;
    setTimeout(() => {
      dispatch(setError(null));
      dispatch(
        change(
          REDUX_FORM_NAME,
          "competitionUploadsOpt[" + index + "].document.fileName",
          rawFile.name
        )
      );
      dispatch(
        change(
          REDUX_FORM_NAME,
          "competitionUploads[" + index + "].document.fileName",
          rawFile.name
        )
      );
      dispatch(
        change(
          REDUX_FORM_NAME,
          "competitionUploads[" + index + "].document.path",
          filename
        )
      );
      dispatch(
        change(
          REDUX_FORM_NAME,
          "competitionUploadsOpt[" + index + "].document.path",
          filename
        )
      );
      dispatch(change(REDUX_FORM_NAME, "tmp", new Date().getTime()));
    }, 10);
  };

  uploadFileError = (e, rawFile) => {
    rawFile.documentId = null;
    rawFile.errorMsg = e.message;

    const { dispatch } = this.props;
    dispatch(setError({ message: e.message }));
    setTimeout(() => {
      this.hideErrorMessage();
    }, 2000);
    dispatch(change(REDUX_FORM_NAME, this.props.source, null));
    dispatch(change(REDUX_FORM_NAME, this.props.previewSource, null));
  };

  uploadFile(file, index) {
    file = file[0];
    const prefix = (Math.random() + 1).toString(36).substring(2);
    const filename = prefix + "/" + file.name.split(".")[0];

    let formData = new FormData();
    formData.append("file", file);
    formData.append("name", filename);

    upload(formData)
      .then(data => {
        this.uploadFileSuccess(data, file, index);
      })
      .catch(e => {
        this.setState({ loading: false });
      });
  }

  hideErrorMessage = () => {
    this.props.dispatch(setError(null));
  };

  checkBankInfoAvailable = (clubId, clubs) => {
    if (
      clubId !== undefined &&
      clubs !== undefined &&
      clubs !== null &&
      clubId !== null &&
      Object.entries(clubs).length > 1
    ) {
      var currentSelectedClub = this.findClub(clubId, clubs);

      if (
        currentSelectedClub &&
        currentSelectedClub.bankInformation &&
        currentSelectedClub.bankInformation.name
      )
        return true;
      else {
        if (currentSelectedClub && this.props.competition.paymentViaDeposit) {
          this.props.dispatch(
            change(REDUX_FORM_NAME, "paymentViaDeposit", false)
          );
        }
        return false;
      }
    }
  };

  findClub = (clubId, clubs) => {
    let club = null;
    for (let [index, value] of Object.entries(clubs))
      if (value.id == clubId) {
        return value;
      }
  };

  updateDates = (competition, oldCompetitionDate, newCompetitionDate) => {
    let diff = moment(
      moment(newCompetitionDate)
        .local()
        .format("YYYY-MM-DD")
    ).diff(
      moment(
        moment(oldCompetitionDate)
          .local()
          .format("YYYY-MM-DD")
      ),
      "days"
    );

    if (competition) {
      let comp = competition;

      if (comp.competitionEndDate) {
        const competitionEndDate = moment(comp.competitionEndDate)
          .local()
          .add(diff, "days");
        comp.competitionEndDate = competitionEndDate.toJSON();
        this.props.dispatch(
          change(REDUX_FORM_NAME, "competitionEndDate", comp.competitionEndDate)
        );
      }

      if (comp.registrationStart) {
        const registrationStart = moment(comp.registrationStart)
          .local()
          .add(diff, "days");
        comp.registrationStart = registrationStart.toJSON();
        this.props.dispatch(
          change(REDUX_FORM_NAME, "registrationStart", comp.registrationStart)
        );
      }

      if (comp.registrationEnd) {
        const registrationEnd = moment(comp.registrationEnd)
          .local()
          .add(diff, "days");
        comp.registrationEnd = registrationEnd.toJSON();
        this.props.dispatch(
          change(REDUX_FORM_NAME, "registrationEnd", comp.registrationEnd)
        );
      }

      if (this.props.squads) {
        for (let squad of this.props.squads) {
          if (squad.startDate) {
            squad.startDate = moment(squad.startDate)
              .local()
              .add(diff, "days")
              .toJSON();
          }
        }
      }
    }
  };

  errorLabel = label => <span style={{ color: "red" }}>{label}</span>;

  getSquadDivisions = ids => {
    const divisions = this.state.divisionChoices.filter(d =>
      ids.includes(d.id)
    );
    this.setState({
      squadDivisions: divisions
    });
  };

  getSquadFactors = ids => {
    if (this.state.factors) {
      const factors = this.state.factors.filter(f => ids.includes(f.id));
      this.setState({
        squadFactors: factors
      });
    }
  };

  getSquadCompetitionClasses = ids => {
    if (this.state.competitionClasses) {
      const competitionClasses = this.state.competitionClasses.filter(cc =>
        ids.includes(cc.id)
      );
      this.setState({
        squadCompetitionClasses: competitionClasses
      });
    }
  };

  render = () => {
    return (
      <WithPermissions
        render={({ permissions }) => (
          <Grid>
            <PageTitle
              title={this.props.translate(
                "resources.competitions.editPage.headline"
              )}
            />

            <Dialog
              open={this.props.popupChangeDates}
              onClose={() => {
                this.props.dispatch(togglePopup("popupChangeDates"));
              }}
            >
              <DialogTitle>
                {this.props.translate(
                  "resources.competitions.editPage.competitionDateChanged"
                )}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {this.props.translate(
                    "resources.competitions.editPage.updateAllDates"
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.props.dispatch(togglePopup("popupChangeDates"));
                  }}
                  color="primary"
                >
                  NEIN
                </Button>
                <Button
                  onClick={() => {
                    this.updateDates(
                      this.state.competition,
                      this.state.oldCompetitionDate,
                      this.state.newCompetitionDate
                    );
                    this.props.dispatch(togglePopup("popupChangeDates"));
                  }}
                  color="primary"
                  autoFocus
                >
                  JA
                </Button>
              </DialogActions>
            </Dialog>

            <Edit
              basePath={this.props.basePath}
              resource={this.props.resource}
              id={this.props.id}
            >
              <TabbedForm
                redirect="list"
                validate={globalPaymentMethodValidator}
                toolbar={<CustomToolbar />}
              >
                <FormTab label="resources.competitions.fields.tabs.competition">
                  {this.checkIsAdmin(permissions) ? (
                    <ReferenceInput
                      source="club.id"
                      reference="clubs"
                      filter={{ active: true }}
                      allowEmpty={false}
                      resource={this.props.resource}
                      validate={
                        this.checkIsAdmin(permissions) ? required() : []
                      }
                      perPage={100}
                      sort={{ field: "name", order: "ASC" }}
                    >
                      <AutocompleteInput optionText="name" />
                    </ReferenceInput>
                  ) : null}
                  <TextInput
                    source="name"
                    validate={[required(), minLength(2)]}
                  />
                  <RichTextInput
                    source="information"
                    toolbar={[
                      ["bold", "italic", "underline", "strike", "link"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ color: [] }]
                    ]}
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                  <ReferenceInput
                    perPage={200}
                    sort={{ order: "asc", field: "name" }}
                    source="competitionTypeId"
                    reference="competitionTypes"
                    validate={required()}
                    onChange={(event, value) => this.getDivisions(value)}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>

                  <FormDataConsumer>
                    {({ formData, ...rest }) => {
                      var image = null;
                      var selectedCompetitionType;
                      if (formData && formData.competitionTypeId) {
                        var obj = this.props.competitionTypes.data;

                        Object.keys(obj).forEach(function(key) {
                          if (obj[key].id == formData.competitionTypeId) {
                            selectedCompetitionType = obj[key];
                          }
                        });

                        if (
                          selectedCompetitionType &&
                          selectedCompetitionType.logo != null
                        ) {
                          var imageId = selectedCompetitionType.defaultImageId;
                          image = selectedCompetitionType.logo;
                        }
                      }

                      return formData && formData.competitionTypeId ? (
                        <img
                          imageHeight={150}
                          src={getCompetitionListImageSrc(image)}
                        ></img>
                      ) : null;
                    }}
                  </FormDataConsumer>

                  <ReferenceInput
                    perPage={200}
                    sort={{ field: "name", order: "ASC" }}
                    reference="levels"
                    source="level.id"
                  >
                    <SelectInput
                      validate={
                        this.checkIsClubAdmin(permissions) ? required() : []
                      }
                      optionText="name"
                    />
                  </ReferenceInput>
                  <NumberInput
                    source="stages"
                    step={1}
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />

                  <NumberInput
                    source="nominationFee"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                  <TextInput source="clubWebsiteLink" />
                </FormTab>
                <FormTab label="resources.competitions.fields.contactHeader">
                  <TextInput
                    source="contact.firstName"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                  <TextInput
                    source="contact.lastName"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                  <TextInput
                    source="contact.email"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                  <TextInput
                    source="contact.telephone"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                </FormTab>
                <FormTab label="resources.competitions.fields.addressHeader">
                  <TextInput
                    source="address"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                  <TextInput
                    source="city"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                  <TextInput
                    source="zip"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                  <SelectInput
                    source="country"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                    choices={RenderCountryOptions()}
                  />
                  <TextInput
                    source="googleMapsLink"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                </FormTab>
                <FormTab label="resources.competitions.fields.dateHeader">
                  <FormDataConsumer>
                    {({ formData, ...rest }) => {
                      return (
                        <DateInput
                          label={this.props.translate(
                            "resources.competitions.fields.date"
                          )}
                          source="competitionDate"
                          providerOptions={{
                            utils: MomentUtils,
                            locale: deLocale
                          }}
                          options={{
                            format: "DD.MM.YYYY",
                            ampm: false,
                            clearable: true,
                            disablePast: true
                          }}
                          validate={
                            this.checkIsClubAdmin(permissions) ? required() : []
                          }
                          onChange={value => {
                            if (
                              !moment(formData.competitionDate)
                                .local()
                                .isSame(moment(value).local(), "day")
                            ) {
                              this.setState({
                                oldCompetitionDate: formData.competitionDate,
                                newCompetitionDate: value,
                                competition: formData
                              });
                              this.props.dispatch(
                                togglePopup("popupChangeDates")
                              );
                            }
                          }}
                          {...rest}
                        />
                      );
                    }}
                  </FormDataConsumer>
                  <DateInput
                    label={this.props.translate(
                      "resources.competitions.fields.endDate"
                    )}
                    source="competitionEndDate"
                    providerOptions={{ utils: MomentUtils, locale: deLocale }}
                    options={{
                      format: "DD.MM.YYYY",
                      ampm: false,
                      clearable: true,
                      disablePast: true
                    }}
                  />
                  <DateTimeInput
                    label={this.props.translate(
                      "resources.competitions.fields.registrationStart"
                    )}
                    source="registrationStart"
                    providerOptions={{ utils: MomentUtils, locale: deLocale }}
                    options={{
                      disablePast: true,
                      format: "DD.MM.YYYY, HH:mm:ss",
                      ampm: false,
                      clearable: true
                    }}
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                  <DateTimeInput
                    label={this.props.translate(
                      "resources.competitions.fields.registrationEnd"
                    )}
                    source="registrationEnd"
                    providerOptions={{ utils: MomentUtils, locale: deLocale }}
                    options={{
                      format: "DD.MM.YYYY, HH:mm:ss",
                      ampm: false,
                      clearable: true,
                      disablePast: true
                    }}
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                </FormTab>
                <FormTab label="resources.competitions.fields.additionalInfo">
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.competitionTypeId ? (
                        <SelectArrayInput
                          source="division_ids"
                          validate={required()}
                          choices={this.state.divisionChoices}
                          optionText="name"
                          onChange={(event, value) =>
                            this.getSquadDivisions(value)
                          }
                          {...rest}
                        />
                      ) : (
                        <SelectArrayInput
                          label="ERROR.SELECT_COMPETITION_TYPE"
                          optionText="name"
                          disabled={true}
                          {...rest}
                        />
                      )
                    }
                  </FormDataConsumer>
                  <ReferenceArrayInput
                    perPage={200}
                    source="competitionClass_ids"
                    validate={required()}
                    reference="competitionClasses"
                    sort={{ field: "name", order: "ASC" }}
                    allowEmpty
                    onChange={(event, value) =>
                      this.getSquadCompetitionClasses(value)
                    }
                  >
                    <SelectArrayInput optionText="name" />
                  </ReferenceArrayInput>
                  <ReferenceArrayInput
                    perPage={200}
                    source="factor_ids"
                    reference="factors"
                    validate={required()}
                    sort={{ order: "asc", field: "name" }}
                    allowEmpty
                    onChange={(event, value) => this.getSquadFactors(value)}
                  >
                    <SelectArrayInput optionText="name" />
                  </ReferenceArrayInput>
                  <NumberInput source="minimumShots" step={1} />
                  <BooleanInput source="multipleRegistrations" />
                  <BooleanInput source="nationalCompetition" />
                  <BooleanInput source="qualification" />
                  <BooleanInput source="state" />
                  <Divider />
                  <BooleanInput source="firstPaymentReminder" />
                  <BooleanInput source="secondPaymentReminder" />

                  <FormDataConsumer>
                    {({ formData, ...rest }) => {
                      {
                        return formData.firstPaymentReminder ||
                          formData.secondPaymentReminder ? (
                          <BooleanInput
                            source="autoSubscriptionCancelation"
                            label={this.props.translate(
                              "resources.competitions.fields.autoSubscriptionCancelation"
                            )}
                          />
                        ) : (
                          <BooleanInput
                            source="disabledAutoSubscriptionCancelation"
                            options={{
                              disabled: true
                            }}
                            label={this.props.translate(
                              "resources.competitions.fields.autoSubscriptionCancelation"
                            )}
                          />
                        );
                      }
                    }}
                  </FormDataConsumer>

                  <Divider />
                  <BooleanInput
                    source="payOnSite"
                    label={
                      !this.props.payOnSite &&
                      !this.props.paymentViaDeposit &&
                      this.props.submitFailed
                        ? this.errorLabel(
                            this.props.translate(
                              "resources.competitions.fields.payOnSite"
                            )
                          )
                        : "resources.competitions.fields.payOnSite"
                    }
                  />
                  {this.checkBankInfoAvailable(
                    this.props.clubId,
                    this.props.clubs
                  ) || isClubAdmin(permissions) ? (
                    <BooleanInput
                      label={
                        !this.props.payOnSite &&
                        !this.props.paymentViaDeposit &&
                        this.props.submitFailed
                          ? this.errorLabel(
                              this.props.translate(
                                "resources.competitions.fields.paymentViaDeposit"
                              )
                            )
                          : "resources.competitions.fields.paymentViaDeposit"
                      }
                      source="paymentViaDeposit"
                      disabled={true}
                    />
                  ) : null}

                  <NumberInput
                    source="payOnTime"
                    step={1}
                    validate={[required(), minValue(9)]}
                  />
                  <RadioButtonGroupInput
                    source="viewType"
                    defaultValue={"ALL"}
                    label="resources.competitions.fields.viewType.label"
                    choices={[
                      {
                        id: "ALL",
                        name: this.props.translate(
                          "resources.competitions.fields.viewType.all"
                        )
                      },
                      {
                        id: "INTERNAL",
                        name: this.props.translate(
                          "resources.competitions.fields.viewType.internal"
                        )
                      },
                      {
                        id: "EXTERNAL",
                        name: this.props.translate(
                          "resources.competitions.fields.viewType.external"
                        )
                      },
                      {
                        id: "UNLISTED",
                        name: this.props.translate(
                          "resources.competitions.fields.viewType.unlisted"
                        )
                      }
                    ]}
                  />
                  {this.props.newCompetitionMailSent === false ? null : (
                    <Chip
                      label={this.props.translate(
                        "resources.competitions.fields.NewCompetitionMailSent"
                      )}
                    />
                  )}
                  {isPortalAdmin(permissions) || isAdmin(permissions) ? (
                    <>
                      <BooleanInput
                        label={this.props.translate(
                          "resources.competitions.fields.paid"
                        )}
                        source="paid"
                      />
                      <Divider />
                    </>
                  ) : null}
                </FormTab>
                <FormTab label="resources.competitions.fields.squadHeader">
                  <ArrayInput source="squads">
                    <SimpleFormIterator>
                      <FormDataConsumer>
                        {form => {
                          return (
                            <>
                              <h1>
                                {parseInt(this.getSquadId(form.id)) + 1}. Squad
                              </h1>
                              <Divider />
                            </>
                          );
                        }}
                      </FormDataConsumer>
                      <TextInput
                        source="name"
                        label="resources.squads.fields.name"
                        validate={[required(), minLength(1), maxLength(255)]}
                      />
                      <TextInput
                        source="information"
                        label="resources.squads.fields.information"
                        validate={required()}
                      />
                      <NumberInput
                        source="squadSize"
                        label="resources.squads.fields.squadSize"
                        validate={[
                          required(),
                          minValue(0),
                          this.validateSquadSize()
                        ]}
                      />
                      <NumberInput
                        source="maxWaitingListSize"
                        label="resources.squads.fields.maxWaitingListSize"
                        validate={[waitingListRequired(), minValue(0)]}
                      />
                      <DateInput
                        source="startDate"
                        label="resources.squads.fields.startDate"
                        options={{ disablePast: true, format: "DD.MM.YYYY" }}
                        providerOptions={{
                          utils: MomentUtils,
                          locale: deLocale
                        }}
                        validate={required()}
                      />
                      <TimeInput
                        source="startTime"
                        label="resources.squads.fields.startTime"
                        options={{ ampm: false }}
                        validate={required()}
                        providerOptions={{
                          utils: MomentUtils,
                          locale: deLocale
                        }}
                      />
                      <TimeInput
                        source="endTime"
                        label="resources.squads.fields.endTime"
                        options={{ ampm: false }}
                        validate={required()}
                        providerOptions={{
                          utils: MomentUtils,
                          locale: deLocale
                        }}
                      />
                      <SelectArrayInput
                        source="divisionIds"
                        label="resources.squads.fields.division"
                        choices={this.state.squadDivisions}
                        optionText="name"
                        resettable
                      />
                      <SelectArrayInput
                        source="competitionClassIds"
                        label="resources.squads.fields.competitionClass"
                        choices={this.state.squadCompetitionClasses}
                        optionText="name"
                        resettable
                      />
                      <SelectArrayInput
                        source="factorIds"
                        label="resources.squads.fields.factor"
                        choices={this.state.squadFactors}
                        optionText="name"
                        resettable
                      />

                      <BooleanInput
                        source="requireCode"
                        label="resources.squads.fields.requireCode"
                      />
                      <FormDataConsumer>
                        {({ formData, getSource, ...rest }) => {
                          const id = rest.id;
                          const squad = this.getSquad(id);
                          if (
                            squad &&
                            squad.stands &&
                            squad.stands.length > 0
                          ) {
                            return (
                              <ArrayInput
                                source={getSource("stands")}
                                label="resources.squads.fields.stands"
                              >
                                <SimpleFormIterator>
                                  <TextInput
                                    source="name"
                                    label="resources.squads.fields.name"
                                    validate={[
                                      required(),
                                      minLength(1),
                                      maxLength(255)
                                    ]}
                                  />
                                </SimpleFormIterator>
                              </ArrayInput>
                            );
                          } else {
                            return (
                              <Button
                                onClick={() => this.toggleStands(id)}
                                variant="outlined"
                              >
                                {this.props.translate(
                                  "resources.squads.addStands"
                                )}
                              </Button>
                            );
                          }
                        }}
                      </FormDataConsumer>
                      <FormDataConsumer>
                        {({ formData, ...rest }) => {
                          const id = rest.id;
                          return (
                            <div
                              style={{
                                marginBottom: "70px",
                                marginTop: "25px",
                                display: "flex"
                              }}
                            >
                              <Button onClick={() => this.copySquad(id)}>
                                {this.props.translate(
                                  "resources.squads.copySquad"
                                )}
                              </Button>
                              <Button onClick={() => this.removeSquad(id)}>
                                {this.props.translate(
                                  "resources.squads.removeSquad"
                                )}
                              </Button>
                            </div>
                          );
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                </FormTab>
                <FormTab label="resources.competitions.uploadTypes">
                  <TextInput source="announcementLink" />
                  <TextInput
                    source="resultListLink"
                    label="resources.squads.fields.resultListLink"
                    validate={link()}
                  />
                  <ArrayInput
                    source="competitionUploadsOpt"
                    label=""
                    style={{ width: "480px" }}
                  >
                    <SimpleFormIterator disableAdd disableRemove>
                      <FormDataConsumer>
                        {({ formData, getSource, ...rest }) => {
                          let re = /(\d+)/;
                          let found = getSource().split(re);
                          const index = found[1];
                          if (formData.competitionUploadsOpt !== undefined) {
                            return [
                              <div style={{ marginTop: "20px" }}>
                                <h3>
                                  {
                                    formData.competitionUploadsOpt[index].type
                                      .name
                                  }
                                </h3>
                                <FileInput
                                  maxSize={20000000}
                                  source={
                                    "competitionUploadsOpt[" +
                                    index +
                                    "].documentRaw"
                                  }
                                  label={""}
                                  multiple={false}
                                  options={{
                                    onDropAccepted: file =>
                                      this.uploadFile(file, index)
                                  }}
                                >
                                  <FileFieldInput
                                    source="id"
                                    title="fileName"
                                  />
                                </FileInput>
                                <S3FileUploadsPreview
                                  source={
                                    formData.competitionUploadsOpt[index]
                                      .document
                                  }
                                  validate={[]}
                                ></S3FileUploadsPreview>
                                {formData.competitionUploadsOpt[index].type
                                  .showName ? (
                                  <p style={{ marginTop: 0 }}>
                                    <label>
                                      Bei den "Frei-Namen Download 1-10" Feldern
                                      werden im Bewerbs-Download Bereich die
                                      File-Namen der bereitgestellten Dateien
                                      angezeigt. Bitte wählen Sie diese daher
                                      dementsprechend!
                                    </label>
                                  </p>
                                ) : null}
                              </div>
                            ];
                          } else {
                            return null;
                          }
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                </FormTab>
              </TabbedForm>
            </Edit>
          </Grid>
        )}
      />
    );
  };
}

const mapStateToProps = state => {
  return {
    httpErrorMsg: state.error.httpError.message,
    permissions: state.identity.userData.permissions,
    competitionTypes: state.admin.resources.competitionTypes,
    clubs: state.admin.resources.clubs.data,
    squads:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.squads
        : null,
    competition: state.form[REDUX_FORM_NAME]
      ? state.form[REDUX_FORM_NAME].values
      : null,
    newCompetitionMailSent:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.newCompetitionMailSent
        : null,
    competitionTypeId:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.competitionTypeId
        : null,
    formExists:
      typeof state.form[REDUX_FORM_NAME] != "undefined" ? true : false,
    competitionUploadsExisting:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.competitionUploads
        : null,
    notificationBlocks:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.notificationBlocks
        : null,
    payOnSite:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.payOnSite
        : null,
    paymentViaDeposit:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.paymentViaDeposit
        : null,
    clubId:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.club.id
        : null,

    submitFailed: state.form[REDUX_FORM_NAME]
      ? state.form[REDUX_FORM_NAME].submitFailed
      : null,

    popupChangeDates: state.flags.popupOpen.popupChangeDates
  };
};

export default connect(mapStateToProps)(translate(CompetitionEdit));
