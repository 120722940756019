import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import deLocale from "date-fns/locale/de";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import moment from "moment-timezone";
import RichTextInput from "ra-input-rich-text";
import React, { Component } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Edit,
  FileInput,
  FormDataConsumer,
  FormTab,
  minLength,
  minValue,
  NumberInput,
  RadioButtonGroupInput,
  REDUX_FORM_NAME,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  translate,
  WithPermissions
} from "react-admin";
import { DateTimeInput } from "react-admin-date-inputs";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { change } from "redux-form";
import CustomToolbar from "../../components/CustomToolbar";
import FileFieldInput from "../../components/inputs/FileFieldInput";
import S3FileUploadsPreview from "../../components/inputs/S3FileUploadsPreview";
import PageTitle from "../../components/PageTitle";
import { setError } from "../../reducer/errorActions";
import {
  getDivisionsFor,
  getNotificationTypes,
  getUploadTypes,
  upload
} from "../../utils/restUtils";
import { isAdmin, isClubAdmin, isPortalAdmin } from "../../utils/utils";
import {
  globalPaymentMethodValidator,
  required,
  waitingListRequired
} from "../../validators/validators";
import countries from "./../../components/pages/country-codes-case-lower.json";

const RenderCountryOptions = () => {
  return countries.map(country => {
    return { id: country.countryCode, name: country.countryName };
  });
};

class EventEdit extends Component {
  state = {
    divisionChoices: [],
    defaultNotificationBlocks: [],
    uploadTypes: null
  };
  copySquad = squadID => {
    try {
      let squads = this.props.squads ? this.props.squads : [];
      let data = squads[squadID.split("[")[1].split("]")[0]];
      let copy = { ...data };
      copy.id = null;
      squads.push(copy);
      this.props.dispatch(change(REDUX_FORM_NAME, "squads", squads));
    } catch (e) {
      /*nothing*/
    }
  };

  initData = () => {
    moment.tz.setDefault("UTC");

    getNotificationTypes().then(
      data => {
        let defaultBlocks = [];
        for (let i = 0; i < data.length; i++) {
          defaultBlocks.push({
            text: "",
            type: data[i]
          });
        }
        this.setState({
          defaultNotificationBlocks: defaultBlocks
        });
      },
      () => {}
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.competitionUploadsExisting &&
      this.state.uploadTypes === null
    ) {
      this.getUploadTypesData();
    }

    if (
      prevProps.formExists &&
      prevState.defaultNotificationBlocks.length === 0 &&
      this.state.defaultNotificationBlocks.length > 0 &&
      this.props.notificationBlocks
    ) {
      let tmpBlocks = [];
      for (let i = 0; i < this.state.defaultNotificationBlocks.length; i++) {
        for (let j = 0; j < this.props.notificationBlocks.length; j++) {
          if (
            this.state.defaultNotificationBlocks[i].type.id ===
            this.props.notificationBlocks[j].type.id
          ) {
            tmpBlocks[i] = this.props.notificationBlocks[j];
          }
        }
        if (!tmpBlocks[i]) {
          tmpBlocks[i] = this.state.defaultNotificationBlocks[i];
        }
      }
      this.props.dispatch(
        change(REDUX_FORM_NAME, "notificationBlocks", tmpBlocks)
      );
    }

    if (prevProps.formExists === false && this.props.formExists === true) {
      this.initData();
    }

    if (
      this.state.divisionChoices.length === 0 &&
      this.props.formExists === true &&
      this.props.competitionTypeId &&
      prevProps.competitionTypeId !== this.props.competitionTypeId //&&
      //this.props.competitionTypeId //&&
      //this.state.divisionChoices &&
      //this.state.divisionChoices.length === 0
    ) {
      getDivisionsFor(this.props.competitionTypeId).then(
        data => {
          this.setState({
            divisionChoices: data.data
          });
        },
        e => {
          this.props.dispatch(setError(e));
        }
      );
    }
  }

  getDivisions = id => {
    if (id) {
      getDivisionsFor(id).then(
        data => {
          this.setState({
            divisionChoices: data.data
          });
          this.props.dispatch(change(REDUX_FORM_NAME, "division_ids", null));
        },
        e => {
          this.props.dispatch(setError(e));
        }
      );
    } else {
      this.setState({ divisionChoices: [] });
    }
  };

  checkIsAdmin = permissions => {
    return isAdmin(permissions);
  };

  checkIsClubAdmin = permissions => {
    return isClubAdmin(permissions);
  };

  getUploadTypesData = () => {
    const competitionUploadsExisting = this.props.competitionUploadsExisting;
    let defaultUploadType = [];
    this.setState({ uploadTypes: "loading" });
    getUploadTypes(0, 100, "sortingOrder", "asc", {}).then(
      data => {
        this.setState({
          uploadTypes: data.data
        });
        let currDoc = null;
        for (let i = 0; i < data.data.length; i++) {
          currDoc = null;
          if (competitionUploadsExisting !== undefined) {
            for (let j = 0; j < competitionUploadsExisting.length; j++) {
              if (data.data[i].id == competitionUploadsExisting[j].type.id) {
                currDoc = competitionUploadsExisting[j].document;
              }
            }
          }
          if (currDoc == null) {
            defaultUploadType.push({
              documentRaw: {
                rawFile: { documentId: null },
                id: null,
                fileName: null
              },
              document: {},
              type: data.data[i]
            });
          } else {
            defaultUploadType.push({
              documentRaw: {
                rawFile: { documentId: currDoc.id },
                id: currDoc.id,
                fileName: currDoc.fileName
              },
              document: currDoc,
              type: data.data[i]
            });
          }
        }
        this.props.dispatch(
          change(REDUX_FORM_NAME, "competitionUploadsOpt", defaultUploadType)
        );
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  uploadFileSuccess = (data, rawFile, index) => {
    const path = data.key.replace(/ /g, "+");

    rawFile.documentId = data.id;
    rawFile.errorMsg = null;
    const { dispatch } = this.props;
    setTimeout(() => {
      dispatch(setError(null));
      dispatch(
        change(
          REDUX_FORM_NAME,
          "competitionUploadsOpt[" + index + "].document.fileName",
          rawFile.name
        )
      );
      dispatch(
        change(
          REDUX_FORM_NAME,
          "competitionUploadsOpt[" + index + "].document.path",
          path
        )
      );
      dispatch(change(REDUX_FORM_NAME, "tmp", new Date().getTime()));
    }, 10);
  };

  uploadFileError = (e, rawFile) => {
    rawFile.documentId = null;
    rawFile.errorMsg = e.message;

    const { dispatch } = this.props;
    dispatch(setError({ message: e.message }));
    setTimeout(() => {
      this.hideErrorMessage();
    }, 2000);
    dispatch(change(REDUX_FORM_NAME, this.props.source, null));
    dispatch(change(REDUX_FORM_NAME, this.props.previewSource, null));
  };

  uploadFile(file, index) {
    file = file[0];
    const prefix = (Math.random() + 1).toString(36).substring(2);
    const filename = prefix + "/" + file.name.split(".")[0];
    file.prefix = prefix;

    let formData = new FormData();
    formData.append("file", file);
    formData.append("name", filename);

    upload(formData)
      .then(data => this.uploadFileSuccess(data, file, index))
      .catch(e => {
        this.setState({ loading: false });
      });
  }

  checkBankInfoAvailable = (clubId, clubs) => {
    if (
      clubId !== undefined &&
      clubs !== undefined &&
      clubs !== null &&
      clubId !== null &&
      Object.entries(clubs).length > 1
    ) {
      var currentSelectedClub = this.findClub(clubId, clubs);

      if (
        currentSelectedClub &&
        currentSelectedClub.bankInformation &&
        currentSelectedClub.bankInformation.name
      )
        return true;
      else {
        if (currentSelectedClub) {
          this.props.dispatch(
            change(REDUX_FORM_NAME, "paymentViaDeposit", false)
          );
        }
        return false;
      }
    }
  };

  findClub = (clubId, clubs) => {
    let club = null;
    for (let [index, value] of Object.entries(clubs))
      if (value.id == clubId) {
        return value;
      }
  };

  errorLabel = label => <span style={{ color: "red" }}>{label}</span>;

  render = () => {
    return (
      <WithPermissions
        render={({ permissions }) => (
          <Grid>
            <PageTitle
              title={this.props.translate("resources.events.editPage.headline")}
            />
            <Edit
              basePath={this.props.basePath}
              resource={this.props.resource}
              id={this.props.id}
            >
              <TabbedForm
                redirect="list"
                validate={globalPaymentMethodValidator}
                toolbar={<CustomToolbar />}
              >
                <FormTab label="resources.competitions.fields.tabs.competition">
                  {this.checkIsAdmin(permissions) ? (
                    <ReferenceInput
                      label="resources.events.fields.club"
                      source="club.id"
                      reference="clubs"
                      perPage={100}
                      sort={{ field: "name", order: "ASC" }}
                      filter={{ active: true }}
                      allowEmpty={false}
                      resource={this.props.resource}
                      validate={
                        this.checkIsAdmin(permissions) ? required() : []
                      }
                      sort={{ field: "name", order: "ASC" }}
                    >
                      <AutocompleteInput optionText="name" />
                    </ReferenceInput>
                  ) : null}
                  <TextInput
                    source="name"
                    validate={[required(), minLength(2)]}
                  />
                  <TextInput source="eventType" validate={required()} />
                  <RichTextInput
                    source="information"
                    toolbar={[
                      ["bold", "italic", "underline", "strike", "link"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ color: [] }]
                    ]}
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />

                  <NumberInput
                    source="nominationFee"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                  <TextInput source="clubWebsiteLink" />
                </FormTab>
                <FormTab label="resources.competitions.fields.contactHeader">
                  <TextInput
                    source="contact.firstName"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                  <TextInput
                    source="contact.lastName"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                  <TextInput
                    source="contact.email"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                  <TextInput
                    source="contact.telephone"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                </FormTab>
                <FormTab label="resources.competitions.fields.addressHeader">
                  <TextInput
                    source="address"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                  <TextInput
                    source="city"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                  <TextInput
                    source="zip"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                  <SelectInput
                    source="country"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                    choices={RenderCountryOptions()}
                  />
                  <TextInput
                    source="googleMapsLink"
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                </FormTab>
                <FormTab label="resources.competitions.fields.dateHeader">
                  <DateTimeInput
                    label={this.props.translate(
                      "resources.competitions.fields.date"
                    )}
                    source="competitionDate"
                    providerOptions={{ utils: MomentUtils, locale: deLocale }}
                    options={{
                      format: "DD.MM.YYYY, HH:mm",
                      ampm: false,
                      clearable: true,
                      disablePast: true
                    }}
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />

                  <DateTimeInput
                    label={this.props.translate(
                      "resources.competitions.fields.registrationStart"
                    )}
                    source="registrationStart"
                    providerOptions={{ utils: MomentUtils, locale: deLocale }}
                    options={{
                      disablePast: true,
                      format: "DD.MM.YYYY, HH:mm:ss",
                      ampm: false,
                      clearable: true
                    }}
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                  <DateTimeInput
                    label={this.props.translate(
                      "resources.competitions.fields.registrationEnd"
                    )}
                    source="registrationEnd"
                    providerOptions={{ utils: MomentUtils, locale: deLocale }}
                    options={{
                      format: "DD.MM.YYYY, HH:mm:ss",
                      ampm: false,
                      clearable: true,
                      disablePast: true
                    }}
                    validate={
                      this.checkIsClubAdmin(permissions) ? required() : []
                    }
                  />
                </FormTab>
                <FormTab label="resources.competitions.fields.additionalInfo">
                  <BooleanInput
                    source="payOnSite"
                    label={
                      !this.props.payOnSite &&
                      !this.props.paymentViaDeposit &&
                      this.props.submitFailed
                        ? this.errorLabel(
                            this.props.translate(
                              "resources.competitions.fields.payOnSite"
                            )
                          )
                        : "resources.competitions.fields.payOnSite"
                    }
                  />
                  {this.checkBankInfoAvailable(
                    this.props.clubId,
                    this.props.clubs
                  ) || isClubAdmin(permissions) ? (
                    <BooleanInput
                      label={
                        !this.props.payOnSite &&
                        !this.props.paymentViaDeposit &&
                        this.props.submitFailed
                          ? this.errorLabel(
                              this.props.translate(
                                "resources.competitions.fields.paymentViaDeposit"
                              )
                            )
                          : "resources.competitions.fields.paymentViaDeposit"
                      }
                      source="paymentViaDeposit"
                      disabled={true}
                    />
                  ) : null}
                  <NumberInput
                    source="payOnTime"
                    step={1}
                    validate={[required(), minValue(9)]}
                  />
                  <NumberInput
                    source="squads[0].squadSize"
                    label="resources.squads.fields.squadSize"
                    validate={(required(), minValue(0))}
                  />
                  <NumberInput
                    source="squads[0].maxWaitingListSize"
                    label="resources.squads.fields.maxWaitingListSize"
                    validate={[waitingListRequired(), minValue(0)]}
                  />
                  <Divider />

                  <BooleanInput source="firstPaymentReminder" />
                  <BooleanInput source="secondPaymentReminder" />

                  <FormDataConsumer>
                    {({ formData, ...rest }) => {
                      {
                        return formData.firstPaymentReminder ||
                          formData.secondPaymentReminder ? (
                          <BooleanInput
                            source="autoSubscriptionCancelation"
                            label={this.props.translate(
                              "resources.competitions.fields.autoSubscriptionCancelation"
                            )}
                          />
                        ) : (
                          <BooleanInput
                            source="disabledAutoSubscriptionCancelation"
                            options={{
                              disabled: true
                            }}
                            label={this.props.translate(
                              "resources.competitions.fields.autoSubscriptionCancelation"
                            )}
                          />
                        );
                      }
                    }}
                  </FormDataConsumer>
                  <Divider />
                  <RadioButtonGroupInput
                    source="viewType"
                    defaultValue={"ALL"}
                    label="resources.competitions.fields.viewType.label"
                    choices={[
                      {
                        id: "ALL",
                        name: this.props.translate(
                          "resources.competitions.fields.viewType.all"
                        )
                      },
                      {
                        id: "INTERNAL",
                        name: this.props.translate(
                          "resources.competitions.fields.viewType.internal"
                        )
                      },
                      {
                        id: "EXTERNAL",
                        name: this.props.translate(
                          "resources.competitions.fields.viewType.external"
                        )
                      },
                      {
                        id: "UNLISTED",
                        name: this.props.translate(
                          "resources.competitions.fields.viewType.unlisted"
                        )
                      }
                    ]}
                  />
                  {isPortalAdmin(permissions) || isAdmin(permissions) ? (
                    <>
                      <BooleanInput
                        label={this.props.translate(
                          "resources.competitions.fields.paid"
                        )}
                        source="paid"
                      />
                      <Divider />
                    </>
                  ) : null}
                </FormTab>
                <FormTab label="resources.competitions.uploadTypes">
                  <TextInput source="announcementLink" />
                  <ArrayInput source="competitionUploadsOpt" label="">
                    <SimpleFormIterator disableAdd disableRemove>
                      <FormDataConsumer>
                        {({ formData, getSource, ...rest }) => {
                          let re = /(\d+)/;
                          let found = getSource().split(re);
                          const index = found[1];
                          if (formData.competitionUploadsOpt !== undefined) {
                            return [
                              <div style={{ marginTop: "20px" }}>
                                <label>
                                  {
                                    formData.competitionUploadsOpt[index].type
                                      .name
                                  }
                                </label>
                                <FileInput
                                  maxSize={20000000}
                                  source={
                                    "competitionUploadsOpt[" +
                                    index +
                                    "].documentRaw"
                                  }
                                  label={""}
                                  multiple={false}
                                  options={{
                                    onDropAccepted: file =>
                                      this.uploadFile(file, index)
                                  }}
                                >
                                  <FileFieldInput
                                    source="id"
                                    title="fileName"
                                  />
                                </FileInput>
                                <S3FileUploadsPreview
                                  source={
                                    formData.competitionUploadsOpt[index]
                                      .document
                                  }
                                  validate={[]}
                                ></S3FileUploadsPreview>
                              </div>
                            ];
                          } else {
                            return null;
                          }
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                </FormTab>
              </TabbedForm>
            </Edit>
          </Grid>
        )}
      />
    );
  };
}

const mapStateToProps = state => {
  return {
    httpErrorMsg: state.error.httpError.message,
    permissions: state.identity.userData.permissions,
    competitionTypes: state.admin.resources.competitionTypes,
    clubs: state.admin.resources.clubs.data,
    squads:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.squads
        : null,
    competitionTypeId:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.competitionTypeId
        : null,
    formExists:
      typeof state.form[REDUX_FORM_NAME] != "undefined" ? true : false,
    competitionUploadsExisting:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.competitionUploads
        : null,
    notificationBlocks:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.notificationBlocks
        : null,
    payOnSite:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.payOnSite
        : null,
    paymentViaDeposit:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.paymentViaDeposit
        : null,
    clubId:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.club.id
        : null,

    submitFailed: state.form[REDUX_FORM_NAME]
      ? state.form[REDUX_FORM_NAME].submitFailed
      : null,
    competition: state.form[REDUX_FORM_NAME]
      ? state.form[REDUX_FORM_NAME].intial
      : null
  };
};

export default connect(mapStateToProps)(translate(withRouter(EventEdit)));
