import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import moment from "moment";
import React, { Component } from "react";
import { translate, WithPermissions } from "react-admin";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CompetitionFlagChips } from "../../components/MiscChips";
import CompetitionList from "../../components/pages/CompetitionList";
import { setError } from "../../reducer/errorActions";
import {
  checkAuth,
  clickAd,
  exportCompetitionList,
  getAdvertisingPage,
  getCompetitionsPub,
  getFavourite,
  getRegistrations
} from "../../utils/restUtils";
import {
  Button,
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelDetails
} from "@material-ui/core";
import { isGuest } from "../../utils/utils";
import download from "downloadjs";
import PageTitle from "../../components/PageTitle";
import { setPopup } from "../../reducer/flagActions";
import CompetitionViewSwitch from "../../components/CompetitionViewSwitch";
import CompetitionListFilter from "../../components/CompetitionListFilter";
import { defaultRowsPerPageOption } from "../../utils/paging";
import withWidth from "@material-ui/core/withWidth";

class CompetitionListPage extends Component {
  state = {
    competitions: [],
    competitionGroups: {},
    currentPage: 0,
    rowsPerPage: defaultRowsPerPageOption,
    totalItemsCount: 0,
    filterValues: {},
    advertising: [],
    adPosition: Math.floor(Math.random() * 2),
    currAd: 0,
    eventCount: 0
  };

  componentDidMount = () => {
    if (!isGuest(this.props.permissions))
      checkAuth().then(success => {}, e => this.props.dispatch(setError(e)));
  };

  getAdvertisingList = () => {
    getAdvertisingPage(0, 100, "id", "desc", {}).then(
      data => {
        let advertising = data.data;

        this.setState({
          advertising: advertising
        });

        this.getData(this.state.currentPage, this.state.rowsPerPage);
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  getData = (page, rowsPerPage) => {
    var jsonString = JSON.stringify(this.state.filterValues);
    var filterString = encodeURI(jsonString);
    getCompetitionsPub(
      page,
      rowsPerPage,
      "competitionDate",
      "asc",
      filterString
    )
      .then(data => this.decorateData(data))
      .then(
        data => {
          this.groupData(data.data);
          this.setState({
            competitions: data.data,
            totalItemsCount: data.total
          });
        },
        e => {
          this.props.dispatch(setError(e));
        }
      );
  };

  exportList = () => {
    var jsonString = JSON.stringify(this.state.filterValues);
    var filterString = encodeURI(jsonString);
    exportCompetitionList(filterString)
      .then(response => {
        return response.blob();
      })
      .then(
        data => {
          let filename =
            "bewerbe-" +
            new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1) +
            "-" +
            new Date().getDate() +
            "_" +
            new Date().getHours() +
            "_" +
            new Date().getMinutes() +
            "_" +
            new Date().getSeconds() +
            ".xlsx";

          download(
            data,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        },
        e => {
          this.props.dispatch(setError(e));
        }
      );
  };

  OpenDetailsButton = competitionId => {
    let path = "/competitionDetails/" + competitionId;
    return (
      <IconButton component={Link} to={path}>
        <ZoomInIcon />
      </IconButton>
    );
  };

  nextAdPostition = () => {
    this.setState({ adPosition: this.state.adPosition + 1 });
  };

  decorateData = data => {
    let f = (favourites, registrations) =>
      new Promise(resolve => {
        this.setState({
          adPosition: Math.floor(Math.random() * 2)
        });
        let lastAd = 0;
        for (let i = 0; i < data.data.length; i++) {
          let item = data.data[i];
          if (this.state.adPosition == i) {
            item["ads"] = true;
            item["ad_index"] = lastAd;
            this.setState({
              adPosition:
                this.state.adPosition + (Math.floor(Math.random() * 3) + 5)
            });
            if (this.state.advertising.length - 1 <= lastAd) {
              lastAd = 0;
            } else {
              lastAd++;
            }
          } else {
            item["ads"] = false;
            item["ad_index"] = -1;
          }
          if (i + 1 == data.data.length) item["ads"] = false;
          if (item.cancelled === true) {
            item.cancelled = (
              <CompetitionFlagChips
                cancelled={true}
                translate={this.props.translate}
              />
            );
          }
          if (favourites !== undefined && favourites !== null)
            item.favourite = favourites.includes(item.id);
          if (registrations !== undefined && registrations !== null) {
            item.registered = registrations[item.id] !== undefined;
            item.paid = registrations[item.id];
          }
          if (item.publicLink) {
            //item.openDetailsButton = this.OpenDetailsButton(item.publicLink);
            item.publicLinkAction = () => {
              this.props.dispatch(
                setPopup("popupCompetitionRegistrationForm", false)
              );
              this.props.history.push("/competitionDetails/" + item.publicLink);
            };
            //delete item.publicLink;
          }
        }

        resolve(data);
      });

    if (!isGuest(this.props.permissions)) {
      return getFavourite().then(favourites => {
        return getRegistrations().then(registrations => {
          return f(favourites, registrations);
        });
      });
    } else {
      return f();
    }
  };

  groupData = data => {
    let groups = [];

    for (let i = 0; i < data.length; i++) {
      let competition = data[i];
      let date = moment(competition.competitionDate).format("YYYY-MM");
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(competition);
    }
    this.setState({ competitionGroups: groups });
  };

  handlePageChange = (event, page) => {
    this.setState({ currentPage: page });
    this.getAdvertisingList();
  };

  handleRowsPerPageChange = event => {
    this.setState({ rowsPerPage: event.target.value });
    this.getAdvertisingList();
  };

  handleSort = (property, order) => {
    getCompetitionsPub(
      this.state.currentPage,
      this.state.rowsPerPage,
      property,
      order,
      { archive: false }
    )
      .then(data => this.decorateData(data))
      .then(
        data => {
          this.groupData(data.data);
          this.setState({
            competitions: data.data,
            totalItemsCount: data.total
          });
        },
        e => {
          this.props.dispatch(setError(e));
        }
      );
  };

  OpenDetailsButton = competitionId => {
    let path = "/competitiondetails/" + competitionId;
    return (
      <IconButton component={Link} to={path}>
        <ZoomInIcon />
      </IconButton>
    );
  };

  hideErrorMessage = () => {
    this.props.dispatch(setError(null));
  };

  handleFilterChange = filterValues => {
    filterValues.archive = false;
    this.setState({ filterValues: filterValues }, () => {
      this.getAdvertisingList();
    });
  };

  handleAdClick = (id, detailView, href) => {
    clickAd(id);
    detailView
      ? this.props.history.push("/werbung/" + id)
      : window.open(href, "_blank");
  };

  checkIfFilterActive = filterValues =>
    filterValues !== null &&
    ((filterValues.year && filterValues.year.length !== 0) ||
      (filterValues.month && filterValues.month.length !== 0) ||
      (filterValues.country && filterValues.country.length !== 0) ||
      (filterValues.zip && filterValues.zip.length !== 0) ||
      (filterValues.club && filterValues.club.length !== 0) ||
      (filterValues.type && filterValues.type.length !== 0) ||
      (filterValues.level && filterValues.level.length !== 0));

  render = () => {
    return (
      <WithPermissions
        render={permissions => (
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <PageTitle
                title={this.props.translate(
                  "resources.competitions.listPage.headline"
                )}
              />
              <ExpansionPanel
                defaultExpanded={true}
                style={{
                  maxWidth:
                    !this.props.competitionViewType &&
                    this.props.width !== "xs" &&
                    this.props.width !== "sm"
                      ? "66.67%"
                      : "100%"
                }}
              >
                <ExpansionPanelDetails>
                  <CompetitionListFilter
                    handleFilterChange={this.handleFilterChange}
                  />
                </ExpansionPanelDetails>
                <ExpansionPanelActions>
                  {this.checkIfFilterActive(this.state.filterValues) ? (
                    <h3 style={{ color: "red", marginRight: "auto" }}>
                      {this.props.translate(
                        "competitionList.filters.filtersActive"
                      )}
                    </h3>
                  ) : null}
                  {!isGuest(permissions.permissions) && (
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={() => this.exportList()}
                      style={{ marginRight: "30px" }}
                    >
                      {this.props.translate(
                        "resources.competitions.listPage.downloadCompetitionList"
                      )}
                    </Button>
                  )}
                </ExpansionPanelActions>
              </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
              <CompetitionViewSwitch
                listType={this.props.competitionViewType}
              />
            </Grid>
            <CompetitionList
              handleAdClick={this.handleAdClick}
              ads={this.state.advertising}
              adPos={this.state.adPosition}
              currPos={this.state.currAd}
              eventCount={this.state.eventCount}
              nextAdPostition={this.nextAdPostition}
              competitions={this.state.competitions}
              competitionGroups={this.state.competitionGroups}
              page={this.state.currentPage}
              rowsPerPage={this.state.rowsPerPage}
              total={this.state.totalItemsCount}
              handlePageChange={this.handlePageChange}
              handleRowsPerPageChange={this.handleRowsPerPageChange}
              sortCallback={this.handleSort}
              errorMessage={this.props.httpErrorMsg}
              hideError={this.hideErrorMessage}
              translate={this.props.translate}
              disable_keys={[
                "id",
                "squads",
                "registrationEnd",
                "registrationStart"
              ]}
              translationPrefix={"competitionList"}
              dateParseString={moment.HTML5_FMT.DATETIME_LOCAL_MS}
              momentFormat={"ddd L"}
              momentFormatWithTime={"ddd LLL"}
              locale={"de-at"}
              listType={
                this.props.competitionViewType && this.props.paymentValid
              }
              dispatch={this.props.dispatch}
            />
          </Grid>
        )}
      />
    );
  };
}

const mapStateToProps = state => {
  return {
    httpErrorMsg: state.error.httpError.message,
    competitionViewType:
      state.identity && state.identity.userData
        ? state.identity.userData.competitionViewType
        : false,
    paymentValid:
      state.identity && state.identity.userData
        ? state.identity.userData.paymentValid == "true" ||
          state.identity.userData.paymentValid == true
        : false,
    permissions: state.identity.userData.permissions
  };
};

export default connect(mapStateToProps)(
  translate(withWidth()(CompetitionListPage))
);
